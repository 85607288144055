<template>
    <div class="collection_banner ">
        <img :src="returnBannerImage()" class="opacity">
        <div class="banner_info">
            <div class="banner_user">
                {{ returnWriter().mb_nick }} gallery
            </div>
            <div class="banner_subject">
                {{ galleryData.c_title }}
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import {mapState} from "vuex";

export default {
    name: "GalleryDetailBannerLayout",
    mixins: [imageOption],
    components: {},
    inject: ['setViewData'],
    provide() {
        return {}
    },
    props: {
        galleryData: {
            default: () => {
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
        this.checkMember();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        returnWriter() {
            return util.isEmpty(this.galleryData.Writer) ? {} : this.galleryData.Writer;
        },
        returnBannerImage() {
            if (util.isEmpty(this.galleryData) || util.isEmpty(this.galleryData.Files) || util.isEmpty(this.galleryData.Files.Background)) {
                return "";
            }
            // return `${this.galleryData.Files.Background[0].org_url}?${this.setImageOptions(1200, 470, 'jpg')}`;
            return `${this.galleryData.Files.Background[0].org_url}`;
        },
        checkMember() {
            if (this.$route.name !== 'GalleryDetail') {
                if (!util.isEmpty(this.galleryData) && !util.isEmpty(this.UserInfo)) {
                    if (this.galleryData.mb_no !== this.UserInfo.mb_no) {
                        this.$router.push(`/gallery/${this.$route.params.idx}`);
                    }
                }
            }
        }
    },
    watch: {
        'galleryData': {
            deep: true,
            handler: function (val, oldVal) {
                this.checkMember();
            }
        }
    },
}
</script>

<style scoped>

</style>
